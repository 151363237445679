<template>
  <div>
  <van-field
      readonly
      :disabled="disabled"
      @click="selectPosition"
      name="address"
      label="设施设备"
      class="form-input"
      :required="required"
      :right-icon="editable?'arrow':''"
      input-align="right"
      placeholder="请选择"
    >
      <template #input>
        <span>{{address}}</span>
      </template>
    </van-field>
    <FifterEquipmentPop @confirm="equipmentConfirm" select-type="radio" :show.sync="FifterEquipmentShow" :projectCodes="[projectCode]" v-if="FifterEquipmentShow"></FifterEquipmentPop>
  </div>
</template>

<script>
import FifterEquipmentPop from './FifterEquipmentPop'

export default {
  name: 'MyFieldOfEquipmentSelect',
  components: {
    FifterEquipmentPop
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    showLocation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledClick: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    address: {
      type: String,
      default: undefined
    },
    addressId: {
      type: String,
      default: undefined
    },
    reallyType: {
      type: String,
      default: undefined
    },
    projectCode: {
      type: String,
      default: undefined
    },
    categoryCode: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      FifterEquipmentShow: false
    }
  },
  methods: {
    selectPosition () {
      if (!this.editable) return
      if (this.disabledClick) {
        this.$toast.fail('请先选择项目')
        return
      }
      this.FifterEquipmentShow = true
    },
    equipmentConfirm (params) {
      const { codes, codeDetailList, groupType, equipType } = params
      console.log(codes, codeDetailList, groupType, equipType)
      this.$emit('update:address', `${groupType?.codeName || codeDetailList[0].projectGroupName}/${codeDetailList[0].codeName}${this.showLocation ? ('「' + codeDetailList[0].location + '」') : ''}`)
      this.$emit('update:addressId', codeDetailList[0].code)
      this.$emit('update:reallyType', codeDetailList[0].type)
      this.$emit('update:categoryCode', codeDetailList[0].categoryCode)
      this.$emit('confirm', codeDetailList, equipType)
    }
  }
}
</script>
