var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          disabled: _vm.disabled,
          label: "主要部件故障原因",
          "label-width": "160px",
          required: _vm.required,
          "right-icon": _vm.editable ? "arrow" : "",
          "input-align": "right",
          placeholder: _vm.editable ? "请选择" : "",
        },
        on: { click: _vm.selectProblemItems },
      }),
      _c("MainFaultConfig", {
        attrs: {
          faultshow: _vm.mainFaultConfigShow,
          categoryCode: _vm.categoryCode,
          reallyType: _vm.reallyType,
          data: _vm.problemList,
          patrolItemName: _vm.patrolItemName,
        },
        on: {
          "update:faultshow": function ($event) {
            _vm.mainFaultConfigShow = $event
          },
          confirm: _vm.malfunctionReasonConfirm,
        },
      }),
      _vm._l(_vm.drawingProblemList, function (item, index) {
        return _c("van-cell", {
          key: index,
          staticClass: "problem-list",
          attrs: { center: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${item.parentName ? item.parentName + "/" : ""}${
                              item.problemName || item.mainPartName
                            }`
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              item.guide && _vm.guide
                ? {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c("MalfunctionGuideIcon", {
                          attrs: {
                            "route-name": _vm.routeName,
                            "point-code": _vm.categoryCode,
                            "main-part-code": item.mainPartCode,
                            "point-type": _vm.reallyType,
                            "really-type": _vm.reallyType,
                          },
                          on: {
                            goToMalfunctionGuide: function ($event) {
                              return _vm.$emit("goToMalfunctionGuide")
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              {
                key: "label",
                fn: function () {
                  return [
                    item.questLabel.length > 0
                      ? _c(
                          "span",
                          { staticClass: "problem-label" },
                          _vm._l(item.questLabel, function (item2, index2) {
                            return _c("span", { key: index2 }, [
                              index2 !== 0
                                ? _c("span", [_vm._v("丨")])
                                : _vm._e(),
                              _vm._v(
                                _vm._s(item2.questLabelName || item2.questLabel)
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }