<template>
  <van-popup v-model="faultshow" position="right" :overlay="false"
      :style="{ width: '100vw', height: '100vh' }" get-container="#app" @open="openHandler">
  <div class="MainFaultConfig">
    <my-nav title="主要部件故障原因" :self-back="true" @back="closePop"></my-nav>
    <div class="check-box" id="box">
      <div class="check-list" v-for="(item, index) in problemRecordDataResp" :key="item.mainPartCode" :id="item.mainPartName">
        <div class="check-wrapper first-check-wrapper">
          <div class="check-left">
            <i class="check-icon" :class="`multi-${item.check}`" @click="firstCheck(index)" ></i>
            <p class="name">{{ item.mainPartName }}{{'('+checkNum(item)+'/'+item.breakDownCauseItemDataResps.length+')'}}</p>
          </div>
          <div
            class="check-right"
            @click="showSecond(index, item.breakDownCauseItemDataResps)"
            :class="{ down: !item.show }"
          ></div>
        </div>
        <div class="second-check-wrapper check-wrapper">
          <transition name="van-slide-down">
            <div class="secon-transition" v-if="item.show">
              <div
                class="second-check-list"
                v-for="(list, secondIndex) in item.breakDownCauseItemDataResps"
                :key="secondIndex"
                @click="checkSecond(index, secondIndex)"
              >
                <i class="check-icon" :class="{ 'second-check': list.check }"></i>
                <p class="name mt-13px">{{ list.questLabelName }}</p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <van-popup v-model="showFaultReason" position="bottom" class="point-detail" >
      <div class="popup-title">
        <span>{{ `已选择${drawingProblemList.length}个故障原因` }}</span>
        <span class="clear" @click="clearList">清空</span>
      </div>
      <van-cell class="problem-list" v-for="(item, index) of drawingProblemList" :key="index" center>
        <template #title>
          <span class="title">
            {{`${item.parentName?item.parentName+'/':''}${item.problemName || item.mainPartName}`}}
          </span>
        </template>
        <template #label>
          <span v-if="item.questLabel.length > 0" class="text-14px pr-30px">
            <span v-for="(item2, index2) of item.questLabel" :key="index2">
              <span v-if="index2 !== 0">丨</span>{{ item2.questLabelName || item2.questLabel}}
            </span>
          </span>
        </template>
        <template #icon>
          <div class="items-oprate">
            <span class="delete-icon" @click="deleteItems(index, item)"></span>
          </div>
        </template>
      </van-cell>
        <div class="popup-mat"></div>
      <div class="flex bottom">
        <div class="w-100px ml-16px mt-17px">共{{drawingProblemList.length}}项 <van-icon @click="reviseIcon" :name="bottomIcon" /></div>
        <div class="confirmBtn" @click="confirm">确认</div>
      </div>
    </van-popup>
     <div class="bottom">
      <div class="w-100px ml-16px mt-17px" @click="reviseIcon">共{{drawingProblemList.length}}项 <van-icon :name="bottomIcon" /></div>
      <div class="confirmBtn" @click="confirm">确认</div>
    </div>
  </div>
  </van-popup>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/index'

export default {
  name: 'mainFaultConfig',
  props: {
    faultshow: {
      type: Boolean,
      default: false
    },
    categoryCode: { // 故障指导图标点击需要 pointCode
      type: String,
      default: undefined
    },
    reallyType: {
      type: String, // 故障指导图标点击需要 pointType
      default: undefined
    },
    data: {
      type: Array,
      default: () => []
    },
    // 用于故障原因列表锚点定位
    patrolItemName: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      showFaultReason: false, // 展示故障原因
      allData: [],
      problemRecordDataResp: [],
      equipmentProblemRecordDataResp: [],
      facilityProblemRecordDataResp: [],
      bottomIcon: 'arrow-down',
      formData: {
        problemId: undefined,
        breakDownCauseId: undefined,
        questLabelId: undefined
      },
      faultReasonList: [],
      allBreakDownData: []
    }
  },
  computed: {
    ...mapState({
      breakDownDataPackage: state => state.dataCache.breakDownDataPackage
    }),
    drawingProblemList () {
      if (!this.faultReasonList || this.faultReasonList.length < 1) return []
      return this.mergeQuestLable(this.faultReasonList)
    }
  },
  methods: {
    async openHandler () {
      this.showFaultReason = false
      this.problemRecordDataResp = []
      this.faultReasonList = this.data
      console.log('openHandler: ', this.breakDownDataPackage)
      console.log('变化faultReasonList: ', this.faultReasonList)
      if (this.breakDownDataPackage) {
        this.allBreakDownData = this.breakDownDataPackage
        this.filterData()
        this.backFilling(this.faultReasonList) // 数据回显
      } else {
        this.$http.get(api.getBreakDownDataPackageReq, { dataVersion: 0 }).then((res) => {
          this.$store.commit('SET_BreakDownDataPackage', res)
          this.allBreakDownData = res
          this.filterData()
          this.backFilling(this.faultReasonList) // 数据回显
        })
      }
    },
    checkNum (item) { // 显示选择的数量
      item.myCheckNum = 0
      item.breakDownCauseItemDataResps.forEach(i => {
        if (i.check) item.myCheckNum++
      })
      return item.myCheckNum
    },
    filterData () {
      if (this.reallyType === '2') {
        this.allData = this.allBreakDownData.facilityProblemRecordDataResp.filter(i => i.categoryCode === this.categoryCode)
      } else {
        this.allData = this.allBreakDownData.equipmentProblemRecordDataResp.filter(i => i.categoryCode === this.categoryCode)
      }
      if (this.allData && this.allData.length > 0) {
        this.problemRecordDataResp = this.allData[0].breakDownPartDataRespList.map((item) => {
          this.$set(item, 'check', 'default') // 给数据增加控制图片的字段
          this.$set(item, 'show', false)
          return item
        })
      }
      this.scrollToCurrent()

      console.log('this.problemRecordDataResp: ', this.problemRecordDataResp)
    },
    scrollToCurrent () {
      setTimeout(() => {
        this.$nextTick(() => {
          document.querySelector('#box').scrollIntoView()
          if (this.patrolItemName) {
            const index = this.problemRecordDataResp.findIndex(i => i.mainPartName === this.patrolItemName)
            if (index === -1) return
            this.$set(this.problemRecordDataResp[index], 'show', true)
            document.querySelector('#' + this.patrolItemName).scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
      }, 0)
    },
    backFilling (passValue) { // 回显数据处理
      // if (!passValue.length) return
      this.problemRecordDataResp.forEach(item => {
        item.breakDownCauseItemDataResps.forEach(problemItem => {
          passValue.forEach((data) => {
            if (data.breakDownCauseId === problemItem.breakDownCauseId) {
              if (passValue.length > 0) {
                this.$set(problemItem, 'check', 'check')
                if (item.breakDownCauseItemDataResps.every(i => i.check === 'check')) { // 判断主要部件中的每项是否全部选中
                  this.$set(item, 'check', 'all')
                } else {
                  this.$set(item, 'check', 'check')
                }
                this.$set(item, 'show', true)
              } else if (passValue.length === 0) {
                this.$set(item, 'check', 'all')
              }
            }
          })
        })
        // 处理二级选中状态
        if (passValue.length === 0) {
          item.breakDownCauseItemDataResps && item.breakDownCauseItemDataResps.forEach(questItem => {
            questItem.check = false
          })
        }
        return item
      })
    },
    // 一级问题选
    firstCheck (index) {
      if (
        this.problemRecordDataResp[index].check === 'default' ||
        this.problemRecordDataResp[index].check === 'check'
      ) {
        if (
          this.problemRecordDataResp[index].breakDownCauseItemDataResps &&
          this.problemRecordDataResp[index].breakDownCauseItemDataResps.length > 0
        ) {
          this.problemRecordDataResp[index].breakDownCauseItemDataResps.forEach((item) => {
            item.check = true
          })
        }
        this.problemRecordDataResp[index].check = 'all'
      } else {
        if (
          this.problemRecordDataResp[index].breakDownCauseItemDataResps &&
          this.problemRecordDataResp[index].breakDownCauseItemDataResps.length > 0
        ) {
          this.problemRecordDataResp[index].breakDownCauseItemDataResps.forEach((item) => {
            item.check = false
          })
        }
        this.problemRecordDataResp[index].check = 'default'
      }
      this._initProlemList()
    },
    // 二级选择显示隐藏
    showSecond (index, list) {
      this.problemRecordDataResp[index].show = !this.problemRecordDataResp[index].show
    },
    // 二级问题标签选择
    checkSecond (index, secondIndex) {
      this.problemRecordDataResp[index].breakDownCauseItemDataResps[secondIndex].check =
        !this.problemRecordDataResp[index].breakDownCauseItemDataResps[secondIndex].check
      let status = true
      let text = 'default'
      this.problemRecordDataResp[index].breakDownCauseItemDataResps.forEach((item) => {
        if (item.check) {
          text = 'check'
        } else {
          status = false
          if (text === 'check') {
            text = 'check'
          } else {
            text = 'default'
          }
        }
      })
      if (status && text === 'check') {
        text = 'all'
      }
      this.problemRecordDataResp[index].check = text
      this._initProlemList()
    },
    // 设置选中的数据
    _initProlemList () {
      this.faultReasonList = []
      this.problemRecordDataResp.forEach(item => {
        if (item.check !== 'default') {
          if (item.breakDownCauseItemDataResps) {
            item.breakDownCauseItemDataResps.forEach(list => {
              if (list.check) {
                this.faultReasonList.push({
                  breakDownCauseId: list.breakDownCauseId,
                  problemId: list.problemId,
                  problemName: list.problemName,
                  questLabelName: list.questLabelName,
                  questLabelId: list.questLabelId,
                  grade: list.grade,
                  breakdownGuideResps: list.breakdownGuideResps,
                  hasBreakdownGuideData: list.hasBreakdownGuideData,
                  rectifyLimit: list.rectifyLimit,
                  mainPartName: item.mainPartName,
                  mainPartCode: item.mainPartCode
                })
              }
            })
          }
        }
      })
    },
    mergeQuestLable (list) {
      return list.reduce((arr, problem) => {
        const index = arr.findIndex(item => item.mainPartCode === problem.mainPartCode)
        if (index > -1) {
          arr[index].questLabel.push({ ...problem })
        } else {
          const { mainPartCode, mainPartName, ...moreVal } = problem
          arr.push({ mainPartCode, mainPartName, questLabel: [{ ...moreVal }] })
        }
        return arr
      }, [])
    },
    changeCheck (obj) {
      const exist = this.faultReasonList.find(i => i.questLabelId === obj.questLabelId)
      exist ? this.faultReasonList.splice(this.faultReasonList.findIndex(i => i.questLabelId === obj.questLabelId), 1) : this.faultReasonList.push(obj)
    },
    reviseIcon () { // 展开
      this.bottomIcon === 'arrow-up' ? this.bottomIcon = 'arrow-down' : this.bottomIcon = 'arrow-up'
      this.showFaultReason = !this.showFaultReason
    },
    clearList () { /* 清空所有故障原因 */
      this.$dialog
        .confirm({
          title: '确定清空所有故障原因吗？'
        })
        .then(() => {
          this.faultReasonList = []
          this.showFaultReason = false
          // 重新刷新页面，取消被x  删除的对应的选中
          this.filterData()
        })
    },
    deleteItems (index, item) { // 清除单项选择
      console.log('item: ', item)
      console.log('index: ', index)
      this.$dialog
        .confirm({
          title: '确定删除该故障原因吗？'
        })
        .then(() => {
          for (let i = 0; i < this.faultReasonList.length; i++) {
            for (let j = 0; j < item.questLabel.length; j++) {
              console.log('item.questLabel[j]: ', item.questLabel[j])
              if (this.faultReasonList[i].questLabelId === item.questLabel[j].questLabelId) {
                console.log(111111)
                console.log('this.faultReasonList[i]: ', this.faultReasonList[i])
                this.faultReasonList.splice(this.faultReasonList.findIndex(i => i.questLabelId === item.questLabel[j].questLabelId), 1)
              }
            }
          }
          console.log('this.faultReasonList222: ', this.faultReasonList)
          // 取消被删除的对应考评项的选中
          this._resetTreeData(item)
        })
    },
    _resetTreeData (deleteItem) { // 删除选择的数据后重置选择树数据
      this.problemRecordDataResp.check = ''
      this.problemRecordDataResp.forEach(item => {
        if (item.mainPartCode === deleteItem.mainPartCode) {
          item.check = 'default'
        }
        item.breakDownCauseItemDataResps && item.breakDownCauseItemDataResps.forEach(list => {
          deleteItem.questLabel.forEach(delItem => {
            if (list.breakDownCauseId === delItem.breakDownCauseId) {
              list.check = false
            }
          })
        })
      })
    },
    confirm () {
      this._initProlemList()
      this.$emit('confirm', this.faultReasonList)
      console.log('this.faultReasonList11: ', this.faultReasonList)
      this.$emit('update:faultshow', false)
    },
    closePop () {
      this.$emit('update:faultshow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.MainFaultConfig{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
}
.bottom {
  position: fixed;
  width: 100%;
  height: 82px;
  bottom: 0px;
  background: #ffffff;
}
.confirmBtn{
  position: absolute;
  right: 16px;
  bottom: 36px;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  background-color: #3366FE;
  color:#FFFFFF;
  font-size: 16px;
}
.borderssss{
  border-bottom: 1px solid #E8E8E8;
}
.point-detail {
  min-height: 200px;
  // max-height: 400px;
  .popup-title {
    height: 48px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-size: 16px;
    color: #999999;
    .clear {
      color: #3366fe;
    }
  }
  .popup-mat {
    height: 80px;
    width: 100%;
  }
  .list-scroll {
    max-height: 300px;
    overflow: auto;
    .popup-list {
      width: 340px;
      margin: 0 auto;
      padding: 6px 0;
      min-height: 68px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .items-info {
        color: #222222;
        width: 260px;
        .problem-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .items-sort {
          color: #8b8f9a;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .popup-list + .popup-list {
      border-top: 1px solid #eeeeee;
    }
    .no-data {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: #999999;
    }
  }
}
.items-oprate {
  position: absolute;
  right: 24px;
  .items-point {
    color: #3366fe;
  }
  .delete-icon {
    display: inline-block;
    background: url("../../asset/icon_delete_grey@2x.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.check-box {
  flex: 1;
  overflow: auto;
  flex-shrink: 0;
  margin-top: 8px;
  padding-bottom: 100px;
  // background-color: #f8f8f8;
  .check-list {
    padding: 0 16px;
    background-color: #fff;
    & + .check-list {
      margin-top: 8px;
    }
    .first-check-wrapper {
      display: flex;
      // align-items:center;
      // justify-content: space-between;
      height: 48px;
      align-self: center;
      .check-left {
        display: flex;
        flex: 1;
        margin-top: 13px;
        box-sizing: border-box;
        overflow: hidden;
        .multi-check {
          background: url("../../asset/multi-check.png") no-repeat;
          background-size: 100% 100%;
        }
        .multi-all {
          background: url("../../asset/multi-all.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .check-right {
        flex: 0 0 22px;
        flex-shrink: 0;
        height: 22px;
        margin-top: 13px;
        background: url("../../asset/check-up.png") no-repeat;
        background-size: 100% 100%;
        transform: rotate(0deg);
        transition: all 0.3s;
        &.down {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }
    }
    .secon-transition {
      overflow: hidden;
    }
    .second-check-wrapper {
      padding-left: 32px;
      overflow: hidden;
      .second-check-list {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: space-between;
      }
    }
    .check-person {
      margin-left: 32px;
      border-top: 1px solid #f5f5f5;
      /deep/ .van-cell {
        padding-left: 0;
        font-size: 16px;
        font-weight: 400;
        color: #222;
        height: 54px;
        display: flex;
        align-items: center;
        .van-icon {
          color: #c5c5c5;
        }
        .van-cell__value {
          font-size: 16px;
          font-weight: 400;
          color: #999;
        }
        &.personActive {
          .van-cell__value {
            color: #222;
          }
        }
        .van-cell__right-icon {
          // font-size: 22px;
          // top: -1px;
        }
      }
    }
    .check-wrapper {
      .check-icon {
        flex: 0 0 24px;
        flex-shrink: 0;
        height: 24px;
        background: url("../../asset/multi-default.png") no-repeat;
        background-size: 100%;
        &.second-check {
          background: url("../../asset/multi-all.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .name {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 11px;
      }
    }
  }
}
</style>
