<template>
  <van-icon name="info-o"  @click="guideHandle"/>
</template>

<script>
export default {
  name: 'MalfunctionGuideIcon',
  props: {
    pointCode: {
      type: String,
      default: undefined
    },
    mainPartCode: {
      type: String,
      default: undefined
    },
    routeName: {
      type: String,
      default: 'Dashboard'
    },
    isDisplayParts: {
      type: Boolean,
      default: false
    },
    reallyType: {
      type: String,
      default: undefined
    }
  },
  // activated () {
  //   this.$store.dispatch('dispatchDeleteRouteCache', this.routeName)
  // },
  methods: {
    guideHandle () {
      this.$emit('goToMalfunctionGuide')
      this.$router.push({ path: '/MalfunctionGuide', query: { pointCode: this.pointCode, pointType: this.reallyType, mainPart: this.mainPartCode, isDisplayParts: this.isDisplayParts } })
    }
  }
}
</script>

<style scoped>

</style>
