/**
 * 递归优化（尾递归）
 * @param { function } fn
 */
export function tco (fn) {
  let value
  let active = false
  const accumulated = []

  return function accumulator () {
    accumulated.push(arguments)
    if (!active) {
      active = true
      while (accumulated.length) {
        value = fn.apply(this, accumulated.shift())
      }
      active = false
      return value
    }
  }
}

/**
 * 遍历树节点
 * @param {Array} data 树数据
 * @param {String} childrenName 子节点名字
 * @param {Function} callback 要对每个节点做什么
 */
export function treeTraversal (data = [], callback = undefined, childrenName = 'children') {
  for (let i = 0; i < data.length; i++) {
    callback && callback(data[i])
    if (data[i][childrenName] && data[i][childrenName].length > 0) {
      treeTraversal(data[i][childrenName], callback, childrenName)
    }
  }
}
