var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          disabled: _vm.disabled,
          name: "address",
          label: "设施设备",
          required: _vm.required,
          "right-icon": _vm.editable ? "arrow" : "",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: _vm.selectPosition },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function () {
              return [_c("span", [_vm._v(_vm._s(_vm.address))])]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.FifterEquipmentShow
        ? _c("FifterEquipmentPop", {
            attrs: {
              "select-type": "radio",
              show: _vm.FifterEquipmentShow,
              projectCodes: [_vm.projectCode],
            },
            on: {
              confirm: _vm.equipmentConfirm,
              "update:show": function ($event) {
                _vm.FifterEquipmentShow = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }