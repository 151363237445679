<template>
  <van-popup v-model="ctShow" :overlay="false" position="right"
    :style="{ height: '100%', width: '100%' }" get-container="#app">
    <Cascader ref="cascaderRef" title="设施设备列表" :initList="equipmentList" root-name="列表" :cb-select="equipmentItem"
      :select-type='selectType' @confirm='getEquipmentVal' :cbGoBack="cbGoBack" required @search="handleSearch" :isAssembly="isAssembly"/>
  </van-popup>
</template>

<script>
import { treeTraversal } from '@/utils/treeTools'
import Cascader from '@/components/Cascader'
import { getEquipCategoryOfflineApiHandler, getGroupOfflineApiHandlers, getOfflineAreaDataOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/FifterEquipment/getOfflineDataHandlers'

export default {
  name: 'FifterEquipmentPop',
  components: { Cascader },
  props: {
    projectCodes: { // 该页面为组件时传过来的值
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    },
    selectType: { // 单选radio或者多选checkbox(默认)
      type: String,
      default: 'checkbox'
    },
    isAssembly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      equipmentCodeBak: undefined, // 第一次请求点的值要存下来，给第二次请求用
      ctShow: this.show, // 避免直接改变props传过来的值
      equipmentList: [],
      groupType: undefined,
      equipType: undefined,
      currentItem: undefined,
      qrCode: []
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    getIsFM () {
      return this.$store.getters.getIsFM
    },
    getIsZG () {
      return this.$store.getters.getIsZG
    }
  },
  watch: {
    isOffline () {
      if (this.currentItem) {
        this.equipmentItem(this.currentItem)
      } else {
        this.equipmentShowFn()
      }
    }
  },
  created () {
    this.equipmentShowFn()
  },
  methods: {
    async equipmentShowFn () { // 打开选择设施设备弹窗
      // this.equipmentVis = true
      // const data = await this.$http.get(this.$api.homeEquipmentTree)
      const data = await this.$apiOffline({
        methods: 'get',
        api: this.$api.homeEquipmentTree,
        params: { projectCodes: (this.projectCodes || []).join(), isEnterFM: (this.getIsFM || this.getIsZG) ? 1 : 0 },
        offlineApiHandlers: getEquipCategoryOfflineApiHandler
      })

      const fn = (i) => {
        i.codeName = i.name
        i.hasChildren = true
        if (!i.children || i.children.length === 0) {
          i.isEquipLastNode = true
        }
      }
      treeTraversal(data, fn)
      this.$nextTick(() => {
        this.equipmentList = data || []
      })
    },
    async equipmentItem (item) { // 根据项目查组团
      this.currentItem = item
      if (item.codeName.indexOf('设施分类') !== -1 || item.codeName.indexOf('设备分类') !== -1) { // 需要获取设施设备类型
        this.equipType = { code: item.code, codeName: item.codeName }
      }
      if (item.isGroup) { // 需要获取组团信息
        this.groupType = { code: item.code, codeName: item.codeName }
      }
      if (item.isGroup) { // 组团层选择后需要将children清空重新查询设施设备
        item.children = []
      }
      if (item.isEquipLastNode) { // 设施设备分类选择后需要将children清空重新查询设施设备
        item.children = []
      }
      // 表示为最后一级的值，不做操作
      if (item?.last) return
      if (item.isGroup) { // 如果现在的数据是组团信息(第二次请求)
        const obj = {
          categoryCode: this.equipmentCodeBak, // 取之前备份的
          groupCode: item.code,
          projectCodes: this.projectCodes || [],
          isEnterFM: (this.getIsFM || this.getIsZG) ? 1 : 0
        }
        const data = await this.$apiOffline({ methods: 'post', api: this.$api.homeEquipmentTreeGetLast, params: obj, offlineApiHandlers: getOfflineAreaDataOfflineApiHandler })
        console.log('选择---data: ', data)
        ;(data || []).forEach(i => {
          i.codeName = i.name
          i.last = true
        })
        this.subsequentAssignmentFn(item, data || [])
        return true
      } else if (item.isEquipLastNode) { // 否则则是第一个请求的
        this.equipmentCodeBak = item.code
        const data = await this.$apiOffline({
          methods: 'get',
          api: this.$api.homeEquipmentTreeGetGroup,
          params: { projectCodes: (this.projectCodes || []).join(), categoryCode: this.equipmentCodeBak, isEnterFM: (this.getIsFM || this.getIsZG) ? 1 : 0 }, // projectCode 最好从内容里面去取
          offlineApiHandlers: getGroupOfflineApiHandlers
        })
        data.forEach(i => {
          i.codeName = i.name
          i.children = []
          i.isGroup = true // 添加一个特殊字段用来标识是第一个请求出来的数据
        })
        this.subsequentAssignmentFn(item, data)
        console.log('obj222: ', data)
        return false
      }
    },
    /**
     * 初始数据过后，后续给children赋值的函数
     * @param {Array} item 当前点击项的数据
     * @param {String} data 要给当前点击项的children赋值的数据
     */
    subsequentAssignmentFn (item = {}, data = []) {
      const fn = (i) => { // 递归比对函数
        if (i.code === item.code && i.name === item.name) {
          this.$set(i, 'children', data)
        }
      }
      treeTraversal(this.equipmentList, fn) // 对整体数据递归，找到code、name都相同的一项，给他的children添加数据
    },
    async getEquipmentVal (codes, codeDetailList, groupType, equipType) {
      if (this.isAssembly) { // 当该页面当组件使用的时候调用以下逻辑
        let qrCode = codeDetailList.map((item) => item.qrCode)
        qrCode = Array.from(new Set(qrCode))// 去重
        this.$router.push({
          path: '/CodeOrderList',
          query: {
            codes: codes.join(','),
            qrCode: qrCode.join(',')
          }
        })
      } else { // 当是页面跳转时逻辑不变
        this.$emit('confirm', {
          codes, codeDetailList, groupType, equipType
        })
      }
    },
    cbGoBack () {
      this.$emit('update:show', false)
    },
    async handleSearch ({ searchValue }) {
      console.log('searchValue: ', searchValue)
      if (!searchValue) return
      const obj = {
        projectCodes: this.projectCodes || [],
        isEnterFM: (this.getIsFM || this.getIsZG) ? 1 : 0
      }
      const data = await this.$apiOffline({ methods: 'post', api: this.$api.homeEquipmentTreeGetLast, params: obj, offlineApiHandlers: getOfflineAreaDataOfflineApiHandler })
      console.log('Search----data: ', data)
      ;(data || []).forEach(i => {
        i.codeName = i.name
        i.last = true
      })
      this.subsequentAssignmentFn({}, data)
      this.$refs.cascaderRef.setSearchList(data.reduce((arr, comp) => {
        if (comp.name.indexOf(searchValue) !== -1) {
          arr.push(comp)
        }
        return arr
      }, []))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
