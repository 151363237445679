var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("van-icon", {
    attrs: { name: "info-o" },
    on: { click: _vm.guideHandle },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }