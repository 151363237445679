<template>
  <div>
    <van-field
      readonly
      :disabled="disabled"
      @click="selectProblemItems"
      label="主要部件故障原因"
      label-width="160px"
      class="form-input"
      :required="required"
      :right-icon="editable?'arrow':''"
      input-align="right"
      :placeholder="editable?'请选择':''"
    />
    <MainFaultConfig
    :faultshow.sync="mainFaultConfigShow"
    :categoryCode="categoryCode"
    :reallyType="reallyType"
    :data="problemList"
    @confirm="malfunctionReasonConfirm"
    :patrolItemName="patrolItemName"
    ></MainFaultConfig>
    <van-cell class="problem-list" v-for="(item, index) of drawingProblemList" :key="index" center>
      <template #title>
        <span class="title">
           {{`${item.parentName?item.parentName+'/':''}${item.problemName || item.mainPartName}`}}
        </span>
      </template>
      <template #right-icon v-if="item.guide && guide">
          <MalfunctionGuideIcon @goToMalfunctionGuide="$emit('goToMalfunctionGuide')"  :route-name="routeName" :point-code="categoryCode" :main-part-code="item.mainPartCode" :point-type="reallyType" :really-type="reallyType"/>
      </template>
      <template #label>
        <span v-if="item.questLabel.length > 0" class="problem-label">
                    <span
                      v-for="(item2, index2) of item.questLabel"
                      :key="index2"
                    >
                      <span v-if="index2 !== 0">丨</span>{{ item2.questLabelName || item2.questLabel}}</span
                    >
        </span>
      </template>
    </van-cell>
  </div>
</template>

<script>
import MalfunctionGuideIcon from './MalfunctionGuideIcon'
import MainFaultConfig from './MainFaultConfig'
export default {
  name: 'MyFieldOfMalfunctionReasonSelect',
  components: { MalfunctionGuideIcon, MainFaultConfig },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledClick: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    guide: {
      type: Boolean,
      default: false
    },
    problemList: {
      type: Array,
      default: () => []
    },
    routeName: {
      type: String,
      default: 'Dashboard'
    },
    pointCode: {
      type: String,
      default: undefined
    },
    categoryCode: {
      type: String,
      default: undefined
    },
    reallyType: {
      type: String,
      default: undefined
    },
    // 用于故障原因列表锚点定位
    patrolItemName: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      breakdownGuideResps: [],
      guideRespsShow: false,
      mainFaultConfigShow: false
    }
  },
  mounted () {
    console.log('------problemList-----', this.problemList)
  },
  computed: {
    drawingProblemList () {
      if (!this.problemList || this.problemList.length < 1) return []
      return this.mergeQuestLable(this.problemList)
    }
  },
  methods: {
    selectProblemItems () {
      if (!this.editable) return
      if (!this.reallyType || !this.categoryCode) {
        this.$toast.fail('请先选择设施设备')
        return
      }
      this.mainFaultConfigShow = true
    },
    malfunctionReasonConfirm (list) {
      this.$emit('confirm', list.reduce((arr, item) => {
        arr.push({ ...item, questLabel: item.questLabelName })
        return arr
      }, []))
    },
    mergeQuestLable (list) {
      this.breakdownGuideResps = list.reduce((arr, problem) => {
        const index = arr.findIndex(item => item.mainPartCode === problem.mainPartCode)
        if (index > -1) {
          arr[index].questLabel.push({ ...problem })
        } else {
          const { mainPartCode, mainPartName, ...moreVal } = problem
          arr.push({ mainPartCode, mainPartName, questLabel: [{ ...moreVal }] })
        }
        return arr
      }, [])
      this.breakdownGuideResps.forEach(item => {
        item.guide = item.questLabel.some(list => list.hasBreakdownGuideData === 1)
      })
      return this.breakdownGuideResps
    }
  }
}
</script>

<style scoped lang="less">
.problem-list {
  background: #F8F8F8;
  .van-cell {
    padding: 0;
  }
  .van-icon-info-o {
    font-size: 24px;
    color: blue;
  }
  .problem-label {
    font-size: 14px;
  }
}
</style>
