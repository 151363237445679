var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100vw", height: "100vh" },
      attrs: { position: "right", overlay: false, "get-container": "#app" },
      on: { open: _vm.openHandler },
      model: {
        value: _vm.faultshow,
        callback: function ($$v) {
          _vm.faultshow = $$v
        },
        expression: "faultshow",
      },
    },
    [
      _c(
        "div",
        { staticClass: "MainFaultConfig" },
        [
          _c("my-nav", {
            attrs: { title: "主要部件故障原因", "self-back": true },
            on: { back: _vm.closePop },
          }),
          _c(
            "div",
            { staticClass: "check-box", attrs: { id: "box" } },
            _vm._l(_vm.problemRecordDataResp, function (item, index) {
              return _c(
                "div",
                {
                  key: item.mainPartCode,
                  staticClass: "check-list",
                  attrs: { id: item.mainPartName },
                },
                [
                  _c(
                    "div",
                    { staticClass: "check-wrapper first-check-wrapper" },
                    [
                      _c("div", { staticClass: "check-left" }, [
                        _c("i", {
                          staticClass: "check-icon",
                          class: `multi-${item.check}`,
                          on: {
                            click: function ($event) {
                              return _vm.firstCheck(index)
                            },
                          },
                        }),
                        _c("p", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(item.mainPartName) +
                              _vm._s(
                                "(" +
                                  _vm.checkNum(item) +
                                  "/" +
                                  item.breakDownCauseItemDataResps.length +
                                  ")"
                              )
                          ),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "check-right",
                        class: { down: !item.show },
                        on: {
                          click: function ($event) {
                            return _vm.showSecond(
                              index,
                              item.breakDownCauseItemDataResps
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "second-check-wrapper check-wrapper" },
                    [
                      _c("transition", { attrs: { name: "van-slide-down" } }, [
                        item.show
                          ? _c(
                              "div",
                              { staticClass: "secon-transition" },
                              _vm._l(
                                item.breakDownCauseItemDataResps,
                                function (list, secondIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: secondIndex,
                                      staticClass: "second-check-list",
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkSecond(
                                            index,
                                            secondIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "check-icon",
                                        class: { "second-check": list.check },
                                      }),
                                      _c("p", { staticClass: "name mt-13px" }, [
                                        _vm._v(_vm._s(list.questLabelName)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "van-popup",
            {
              staticClass: "point-detail",
              attrs: { position: "bottom" },
              model: {
                value: _vm.showFaultReason,
                callback: function ($$v) {
                  _vm.showFaultReason = $$v
                },
                expression: "showFaultReason",
              },
            },
            [
              _c("div", { staticClass: "popup-title" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(`已选择${_vm.drawingProblemList.length}个故障原因`)
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "clear", on: { click: _vm.clearList } },
                  [_vm._v("清空")]
                ),
              ]),
              _vm._l(_vm.drawingProblemList, function (item, index) {
                return _c("van-cell", {
                  key: index,
                  staticClass: "problem-list",
                  attrs: { center: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${
                                      item.parentName
                                        ? item.parentName + "/"
                                        : ""
                                    }${item.problemName || item.mainPartName}`
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "label",
                        fn: function () {
                          return [
                            item.questLabel.length > 0
                              ? _c(
                                  "span",
                                  { staticClass: "text-14px pr-30px" },
                                  _vm._l(
                                    item.questLabel,
                                    function (item2, index2) {
                                      return _c("span", { key: index2 }, [
                                        index2 !== 0
                                          ? _c("span", [_vm._v("丨")])
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(
                                            item2.questLabelName ||
                                              item2.questLabel
                                          ) + " "
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "items-oprate" }, [
                              _c("span", {
                                staticClass: "delete-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItems(index, item)
                                  },
                                },
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("div", { staticClass: "popup-mat" }),
              _c("div", { staticClass: "flex bottom" }, [
                _c(
                  "div",
                  { staticClass: "w-100px ml-16px mt-17px" },
                  [
                    _vm._v(
                      "共" + _vm._s(_vm.drawingProblemList.length) + "项 "
                    ),
                    _c("van-icon", {
                      attrs: { name: _vm.bottomIcon },
                      on: { click: _vm.reviseIcon },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "confirmBtn", on: { click: _vm.confirm } },
                  [_vm._v("确认")]
                ),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "bottom" }, [
            _c(
              "div",
              {
                staticClass: "w-100px ml-16px mt-17px",
                on: { click: _vm.reviseIcon },
              },
              [
                _vm._v("共" + _vm._s(_vm.drawingProblemList.length) + "项 "),
                _c("van-icon", { attrs: { name: _vm.bottomIcon } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "confirmBtn", on: { click: _vm.confirm } },
              [_vm._v("确认")]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }